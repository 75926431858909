.home-container {
    position: relative;
    height: 100vh;
    background: center center fixed;
    background-size: cover;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border: none;

}

.home-title {
    font-size: 6rem;
    margin-bottom: 20px;
    /* font-weight: bold; */
}

.home-mission {
    font-size: 3rem;
    opacity: 0.8;
}

.home-button {
    margin-top: 5%;
    margin-left: 65%;
}

.home-title,
.home-mission {
    font-style: italic;
    color: var(--bs-white);
        margin-right: 65%;

}


@media (max-width: 1280px) and (min-width: 769px) {

    .home-title,
    .home-mission {
        margin-right: 15%;
    }
}

@media (max-width: 768px) {

    .home-title {
        font-size: 4rem;
    }

    .home-mission {
        font-size: 2rem;
    }

    .home-title,
    .home-mission {
        margin-right: 5%;
    }
}

@import url(https://fonts.googleapis.com/css?family=Raleway:300);

:root {
    /* --btn-border: 1px solid rgba(255, 255, 255, 0.2); */
    --btn-border: 1px solid rgb(255, 255, 255);
    /* --btn-bg: transparent; */
    --btn-bg-s: rgb(255, 255, 255);
    --btn-shadow: 1px 1px 25px 10px rgba(255, 255, 255, 0.849);
    --btn-text-color-s: #f4f4f4;
    --shine-degree: 120deg;
    --shine-color: rgba(255, 255, 255, 0.959);
    --bs-btn-color: rgba(255, 255, 255, 0.959);
    --bs-btn-hover-color: rgba(255, 255, 255, 0.959);
    --shine-effect: linear-gradient(var(--shine-degree),
            transparent,
            var(--shine-color),
            transparent);
    --shine-transition: all 0.65s ease-in-out;
}


.btn-shine {
    position: relative;
    overflow: hidden;
    font-size: 1.1rem;
    padding: 1rem 3rem;
    border-radius: 4px;
    text-decoration: none;
    border: var(--btn-border);
    color: var(--btn-text-color-s);
    background: var(--btn-bg-s);
}

.btn-shine:not(:last-child) {
    margin-bottom: 3rem;
}

.btn-shine::before {
    content: "";
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background: var(--shine-effect);
}

.btn-shine-hover::before {
    left: -100%;
    transition: var(--shine-transition);
}

.btn-shine-hover:hover {
    box-shadow: var(--btn-shadow);
}

.btn-shine-hover:hover::before {
    left: 100%;
}

.btn-shine-anim::before {
    -webkit-animation: shine 5s ease-in-out infinite;
    animation: shine 5s ease-in-out infinite;
}

@-webkit-keyframes shine {
    0% {
        left: -100%;
        transition-property: left;
    }

    11.5044247788%,
    100% {
        left: 100%;
        transition-property: left;
    }
}

@keyframes shine {
    0% {
        left: -100%;
        transition-property: left;
    }

    11.5044247788%,
    100% {
        left: 100%;
        transition-property: left;
    }
}

.btn-shine-alt {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    width: 250px;
    height: 60px;
    font-size: 1.1rem;
    position: relative;
    overflow: hidden;
    border-radius: 4px;
    text-decoration: none;
    border: var(--btn-border);
    color: var(--btn-text-color-s);
    background: var(--btn-bg-s);
}

.btn-shine-alt:not(:last-child) {
    margin-bottom: 3rem;
}

.btn-shine-alt::before,
.btn-shine-alt::after {
    content: "";
    display: block;
    position: absolute;
    height: 100%;
    top: 0;
}

.btn-shine-alt:before {
    background: rgba(255, 255, 255, 0.801);
    width: 60px;
    left: 0;
    filter: blur(30px);
    -webkit-animation: shine-alt1 5s ease-in-out infinite;
    animation: shine-alt1 5s ease-in-out infinite;
}

.btn-shine-alt:after {
    background: rgba(255, 255, 255, 0.61);
    width: 30px;
    left: 30px;
    filter: blur(5px);
    -webkit-animation: shine-alt2 5s ease-in-out infinite;
    animation: shine-alt2 5s ease-in-out infinite;
}

@-webkit-keyframes shine-alt1 {
    0% {
        opacity: 0.5;
        transform: translateX(-100px) skewX(-15deg);
        transition-property: opacity, transform;
    }

    11.5044247788%,
    100% {
        opacity: 0.6;
        transform: translateX(300px) skewX(-15deg);
        transition-property: opacity, transform;
    }
}

@keyframes shine-alt1 {
    0% {
        opacity: 0.5;
        transform: translateX(-100px) skewX(-15deg);
        transition-property: opacity, transform;
    }

    11.5044247788%,
    100% {
        opacity: 0.6;
        transform: translateX(300px) skewX(-15deg);
        transition-property: opacity, transform;
    }
}

@-webkit-keyframes shine-alt2 {
    0% {
        opacity: 0;
        transform: translateX(-100px) skewX(-15deg);
        transition-property: opacity, transform;
    }

    11.5044247788%,
    100% {
        opacity: 1;
        transform: translateX(300px) skewX(-15deg);
        transition-property: opacity, transform;
    }
}

@keyframes shine-alt2 {
    0% {
        opacity: 0;
        transform: translateX(-100px) skewX(-15deg);
        transition-property: opacity, transform;
    }

    11.5044247788%,
    100% {
        opacity: 1;
        transform: translateX(300px) skewX(-15deg);
        transition-property: opacity, transform;
    }
}
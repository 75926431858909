.myCard {
    width: '90%';
    height: '80%';
    color: red;
}

@media (max-width: 768px) {

    .myCard {
        width: '100%';
        font-size: small;
    }
}